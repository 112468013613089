var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.detmodal)?_c('Detalhes',{attrs:{"consulta":_vm.objeto},on:{"detclose":_vm.fecharDetalhes}}):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"px-6 py-6"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.gridRet.objetos.cabecalho,"items":_vm.gridRet.objetos.items,"items-per-page":_vm.gridRet.objetos.porPagina,"hide-default-footer":"","loading":_vm.gridRet.objetos.carregando,"item-key":"codigo"},scopedSlots:_vm._u([{key:"item.numero_rastreio",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.carregarDetalhes(item)}}},[_vm._v(" "+_vm._s(item.numero_rastreio)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({},on),[_c('v-icon',{staticClass:"acoes",attrs:{"size":"16"},on:{"click":_vm.MarkAsRead}},[_vm._v("fas fa-envelope")])],1)]}}],null,true)},[_c('span',[_vm._v("Marcar como Lido")])])]}},_vm._l((_vm.gridRet.objetos.cabecalho.filter(function (header) { return header.hasOwnProperty('formatter'); } )),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}})],null,true),model:{value:(_vm.gridRet.objetos.itemSelecionado),callback:function ($$v) {_vm.$set(_vm.gridRet.objetos, "itemSelecionado", $$v)},expression:"gridRet.objetos.itemSelecionado"}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"fab":"","small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.voltarTopo()}}},[_c('v-icon',[_vm._v(" fas fa-arrow-up ")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-pagination',{attrs:{"length":Math.ceil( this.gridRet.objetos.totalRegistros / this.gridRet.objetos.porPagina ),"total-visible":5},on:{"input":_vm.mudarPagina},model:{value:(_vm.gridRet.objetos.paginaAtual),callback:function ($$v) {_vm.$set(_vm.gridRet.objetos, "paginaAtual", $$v)},expression:"gridRet.objetos.paginaAtual"}})],1),_c('v-col',{staticClass:"px-6 py-6",staticStyle:{"padding-bottom":"0px !important"},attrs:{"cols":"2"}},[_c('v-select',{attrs:{"dense":"","outlined":"","headers":_vm.gridRet.objetos.cabecalho,"label":"Resultados por página","items":_vm.gridRet.objetos.qtdsPaginacao,"item-text":"text","item-value":"value"},model:{value:(_vm.gridRet.objetos.porPagina),callback:function ($$v) {_vm.$set(_vm.gridRet.objetos, "porPagina", $$v)},expression:"gridRet.objetos.porPagina"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }