<template>
  <div>
    <!-- Início detalhes -->
    <Detalhes
      v-if="detmodal"
      v-on:detclose="fecharDetalhes"
      :consulta="objeto"
    />
    <!-- Fim detalhes -->

    <v-row>
      <v-col class="px-6 py-6">
        <v-data-table
          dense
          :headers="gridRet.objetos.cabecalho"
          :items="gridRet.objetos.items"
          :items-per-page="gridRet.objetos.porPagina"
          hide-default-footer
          class="elevation-1"
          :loading="gridRet.objetos.carregando"
          item-key="codigo"
          v-model="gridRet.objetos.itemSelecionado"
        >
          <template #item.numero_rastreio="{ item }">
            <a @click="carregarDetalhes(item)" style="text-decoration: none">
              {{ item.numero_rastreio }}
            </a>
          </template>
          <!-- <template #item.protocolo_retorno="{ item }">
            <a @click="carregarPi(item)" style="text-decoration: none">
              {{ item.protocolo_retorno }}
            </a>
          </template> -->

          <template v-slot:item.acoes="{ item }">
            <v-tooltip bottom >
              <template v-slot:activator="{ on }">
                <!-- <v-app-bar-nav-icon v-on="on" > -->
                  <!-- <v-icon size="16" color="green" class="acoes" @click="responderManifesto(item)">fas fa-pen</v-icon> -->
                <v-app-bar-nav-icon v-on="on" >
                  <v-icon size="16" class="acoes" @click="MarkAsRead" >fas fa-envelope</v-icon>
                </v-app-bar-nav-icon>
              </template>
              <span>Marcar como Lido</span>
            </v-tooltip>
          </template>

          <template v-for="header in gridRet.objetos.cabecalho.filter((header) => header.hasOwnProperty('formatter') )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="2">
        <v-btn
          fab
          small
          color="primary"
          dark
          @click="voltarTopo()"
          style="margin-left: 10px"
        >
          <v-icon> fas fa-arrow-up </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="8">
        <v-pagination
          v-model="gridRet.objetos.paginaAtual"
          :length=" Math.ceil( this.gridRet.objetos.totalRegistros / this.gridRet.objetos.porPagina ) "
          @input="mudarPagina"
          :total-visible="5"
        >
        </v-pagination>
      </v-col>
      <v-col cols="2" class="px-6 py-6" style="padding-bottom: 0px !important">
        <v-select
          dense
          outlined
          :headers="gridRet.objetos.cabecalho"
          label="Resultados por página"
          :items="gridRet.objetos.qtdsPaginacao"
          item-text="text"
          item-value="value"
          v-model="gridRet.objetos.porPagina"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Detalhes from "../../objetos/frmdetalhes.vue";

export default {
  name: "Analise",
  components: {
    Detalhes,
  },
  props: {
    // function: { type: Function },
    filtro: {type: Object },
    typereclama: { type: String },
  },
  data: () => ({
    gridRet: {
      codigo_empresa: '',
      objetos: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "PI", value: "protocolo_retorno", sortable: false },
          { text: "Data Prevista", value: "data_prevista", sortable: true, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Reclamação", value: "data_reclamacao", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Motivo", value: "motivo", sortable: false },
          { text: "Retorno", value: "texto", sortable: false },
          { text: "", value: "acoes", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        // reclama: [
        //   { text: "TODOS", value: "todos" },
        //   { text: "ABRIR", value: "abertura" },
        //   { text: "PI", value: "pi" },
        // ],
        totalRegistros: 0,
        qtdsPaginacao: [10, 15, 50, 100],
        porPagina: 50,
        itemSelecionado: [],
        coluna: "reclamacoes.codigo",
        direcao: "ASC",
        ordenacao: {
          colunas: [
            { text: "Código", value: "reclamacoes.codigo" },
            { text: "Rastreio", value: "reclamacoes.numero_rastreio" },
            { text: "PI", value: "reclamacoes.protocolo_retorno" },
            { text: "Data Prevista", value: "objetos.data_prevista" },
            { text: "Data Reclamação", value: "reclamacoes.data_reclamacao" },
            { text: "Motivo", value: "reclamacoes.motivo" },
            { text: "Retorno", value: "reclamacoes.msg_retorno" },
          ],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
      manifesto: {
        cabecalho: [
          { text: "Data", value: "data", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Assunto", value: "assunto", sortable: false },
          { text: "Recebido", value: "texto", sortable: false },
          { text: "Enviado", value: "resposta", sortable: false },
          { text: "Prazo", value: "prazo", sortable: false },
          { text: "Pagamento", value: "pagamento", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Ações", value: "controls", sortable: false },
        ],
        items: [],
        carregando: false,
      },
    },
    filtros: {
      codigo: "",
      numero_rastreio: "",
      pi: "",
      dt_prevista: "",
    },
    detalhes: {
      enviado: {
        data_postagem: "",
        data_considerada: "",
        cep: "",
        cidade: "",
      },
      para: {
        nome: "",
        cep: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      previsao_de_entrega: {
        data: "",
        em_domicilio: "",
      },
      entregue_em: {
        data: "",
      },
    },
    objeto: {
      item: {},
      detalhes: false,
      habilita: false,
    },
    modal: {
      filtro: false,
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      pi: false,
      loading: false,
      objetoAtual: "",
      detalhes: {},
      habilita: false,
    },
    detmodal: false,
  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async beforeMount() {
    this.modal.detalhes = false;
    await this.carregarReclamacoes();
    this.$emit("TotalObjetos", this.gridRet.objetos.totalRegistros);
  },
  methods: {
    async carregarReclamacoes() {
      this.modal.loading = true;
      this.gridRet.objetos.items = [];
      this.gridRet.objetos.carregando = true;
      let url = `${baseApiUrl}/reclamacoes-vencido`;
      url += `?page=${this.gridRet.objetos.paginaAtual}`;
      url += `&coluna=${this.gridRet.objetos.coluna}`;
      url += `&direcao=${this.gridRet.objetos.direcao}`;
      url += `&type=${this.typereclama}`;
      url += `&vencido=0`;

      if (localStorage.getItem("factory"))
        url += `&cod_empresa=${localStorage.getItem("factory")}`;
      if (this.filtros.codigo != "") url += `&codigo=${this.filtros.codigo}`;
      if (this.filtros.numero_rastreio != "")
        url += `&numero_rastreio=${this.filtros.numero_rastreio}`;
      if (this.filtros.pi != "") url += `&pi=${this.filtros.pi}`;
      if (this.filtros.dt_prevista != "")
        url += `&data_prevista=${this.filtros.dt_prevista}`;
      if (Number(this.gridRet.objetos.porPagina) > 0)
        url += `&por_pagina=${this.gridRet.objetos.porPagina}`;

      await axios
        .get(url)
        .then((res) => {
          if (res.data.retorno) {
            this.gridRet.objetos.items = res.data.dados.data;
            this.gridRet.objetos.totalRegistros = res.data.dados.total;
            this.gridRet.objetos.porPagina = Number(res.data.dados.per_page);
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => (this.gridRet.objetos.carregando = false));
      this.modal.loading = false;
    },
    async carregarDetalhes(item) {
// console.log( "Analise item", item);
      // this.objeto.item = item;
      // this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      // this.objeto.detalhes = true;
      // this.detmodal = true;
      // this.modal.loading = false;
      this.objeto.item = item;
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = Number(item.protocolo_retorno) > 0;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.detmodal = true;
      this.modal.loading = false;
    },
    fecharDetalhes(Atualizar) {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
      }
      if (Atualizar) {
        this.carregarReclamacoes();
      }
    },
    MarkAsRead(item) {

      this.$swal({
        title: 'MARCAR COMO LIDO?',
        text: `Alterar Status`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: '#4BB543',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
        }
      });
    },
    mudarPagina(pagina) {
      this.gridRet.objetos.paginaAtual = pagina;
      this.carregarReclamacoes();
    },
    voltarTopo() {
      window.scrollTo(0, 0);
    },
    formatarNumeroBR(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatarDataBR(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/yyyy");
      }
    },
  },
  watch: {
    'filtro': function (newVal, oldVal){
      if (newVal != oldVal) {
        this.filtros = this.filtro;
        this.carregarReclamacoes();
      }
    },
    'typereclama': function (newVal, oldVal){
      if (newVal != oldVal) {
        this.carregarReclamacoes();
      }
    },
    "gridRet.objetos.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.gridRet.objetos.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.gridRet.objetos.porPagina = 50;
            this.mudarPagina(1);
          }
        }
      },
    },
  },
};
</script>

<style>
</style>