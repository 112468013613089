<template>
  <div>
    <!-- Início filtro -->
		<Filtro v-if="modal.filtro" v-on:filtro="carregarFiltro" v-on:filtroFechar="fecharFiltro" :filtro="modal" />
    <!-- Fim filtro -->

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-bullhorn </v-icon>
        <v-toolbar-title class="pl-4">
          Reclamações {{ titleTab }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="modal.filtro = true">
          <v-icon>fas fa-search</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <!-- <v-row> -->
          <!-- <v-col
            cols="2"
            class="px-6 py-6"
            style="padding-bottom: 0px !important"
          >
            <v-select
              dense
              outlined
              label="Tipo Reclamação"
              :items="grid.objetos.reclama"
              item-text="text"
              item-value="value"
              v-model="grid.objetos.typereclama"
            ></v-select>
          </v-col> -->
          <!-- <v-col
            cols="2"
            class="px-6 py-6"
            style="padding-bottom: 0px !important"
          >
            <v-text-field
              dense
              outlined
              label="Objetos"
              type="text"
              autocomplete="off"
              v-model="grid.objetos.totalRegistros"
              :disabled="true"
              clearable
            ></v-text-field>
          </v-col> -->
        <!-- </v-row>
        <v-divider></v-divider> -->
        <v-row>
          <v-col cols="12" class="px-6">
            <v-tabs
              color="#fff"
              v-model="tabOrd"
              slider-color="red"
              flat
              active-class="active"
            >
              <v-tab v-for="item in tabDat"
                :key="item.value"
                @click="getSearch(item.value)"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-6 py-6">
            <v-tabs
              color="#fff"
              v-model="tab"
              slider-color="red"
              flat
              active-class="active"
            >
              <v-tab
                v-for="(item, index) in items"
                :key="item.value"
                @click="getFieldText(item.tab)"
                v-on:TotalObjetos="objetosQtde"
              >
                {{ item.tab }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(item, index) in items" :key="item.value">
                <v-card flat>
                  <v-card-text>
                    <component :cadastro="detalhes" :filtro="filtros" :typereclama="typereclama" v-bind:is="item.content" ></component>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->
  </div>
</template>

<script>
import { baseApiUrl, showSuccess, showError } from "@/global";
import axios from "axios";
import moment from "moment";
import Filtro from "../../components/objetos/frmfiltro.vue"
import Retorno from "../../components/reclamacoes/correios/frmretorno.vue";
import Prazo from "../../components/reclamacoes/correios/frmprazo.vue";
import Analise from "../../components/reclamacoes/correios/frmanalise.vue";
import Vencido from "../../components/reclamacoes/correios/frmvencido.vue";
import Pagamento from "../../components/reclamacoes/correios/frmpagamento.vue";
import Sla from "../../components/reclamacoes/correios/frmsla.vue";

export default {
  name: "Reclamacoes",
  components: {
    Filtro,
    Retorno,
    Prazo,
    Analise,
    Vencido,
    Pagamento,
    Sla,
  },
  data: () => ({
    tabOrd: 0,
    tabDat: [
      { tab: "Abertura", content: "abertura", value: 1 },
      { tab: "Reativação", content: "reativacao", value: 2 },
    ],
    typereclama: "abertura",

    titleTab: "",
    tab: 1,
    items: [
      { tab: "Aguardando Retorno", content: "Retorno", value: 1 },
      { tab: "Aguardando Prazo", content: "Prazo", value: 2 },
      { tab: "Analise Retorno", content: "Analise", value: 3 },
      { tab: "Prazo Vencido", content: "Vencido", value: 4 },
      { tab: "Aguardando Pagamento", content: "Pagamento", value: 5 },
      { tab: "Retorno SLA", content: "Sla", value: 6 },
    ],
    grid: {
      objetos: {
        cabecalho: [
          { text: "#", value: "codigo", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "PI", value: "protocolo_retorno", sortable: false },
          { text: "Data Prevista", value: "data_prevista", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Data Reclamação", value: "data_reclamacao", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Motivo", value: "motivo", sortable: false },
          { text: "Retorno", value: "msg_retorno", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        reclama: [
          { text: "TODOS", value: "todos" },
          { text: "ABRIR", value: "abertura" },
          { text: "PI", value: "pi" },
        ],
        typereclama: "todos",
        totalRegistros: 0,
        itemSelecionado: [],
        coluna: "reclamacoes.codigo",
        direcao: "ASC",
        ordenacao: {
          colunas: [
            { text: "Código", value: "reclamacoes.codigo" },
            { text: "Rastreio", value: "reclamacoes.numero_rastreio" },
            { text: "PI", value: "reclamacoes.protocolo_retorno" },
            { text: "Dt. Prev", value: "objetos.data_prevista" },
            { text: "Dt. Recl", value: "reclamacoes.data" },
            { text: "Motivo", value: "reclamacoes.motivo" },
            { text: "Retorno", value: "reclamacoes.msg_retorno" },
          ],
          direcao: [
            { text: "Asc", value: "ASC" },
            { text: "Desc", value: "DESC" },
          ],
        },
      },
      detalhes: {
        cabecalho: [
          { text: "Status", value: "descricao", sortable: false },
          { text: "Data", value: "data_hora", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Origem", value: "origem_cidade_uf_local", sortable: false },
          { text: "Destino", value: "destino_cidade_uf_local", sortable: false, },
        ],
        items: [],
        carregando: false,
      },
      manifesto: {
        cabecalho: [
          { text: "Data", value: "data", sortable: false, formatter: (x) => (x ? moment(x).format("DD/MM/yyyy") : null), },
          { text: "Assunto", value: "assunto", sortable: false },
          { text: "Recebido", value: "texto", sortable: false },
          { text: "Enviado", value: "resposta", sortable: false },
          { text: "Prazo", value: "prazo", sortable: false },
          { text: "Pagamento", value: "pagamento", sortable: false },
          { text: "Status", value: "status", sortable: false },
          { text: "Ações", value: "controls", sortable: false },
        ],
        items: [],
        carregando: false,
      },
    },
    modal: {
      filtro: false,
      name: "Objetos Reclamados",
      cod_transportadora: false,
      dt_considerada: false,
      dt_prevista: false,
      dt_entrega: false,
      dt_ultima_posicao: false,
      rastreio: false,
      detalhes: false,
      pilocal: false,
      pi: true,
      loading: false,
      habilita: false,
    },
    filtros: {},
    objetoAtual: "",
    habilita: false,
    protocolo_retorno: "",
    Motivo: "",
    filtro: false,
    filtroFechar: false,
    rastreios: [],
    detalhes: [],
    loading: false,
    detalhes: {   enviado: { data_postagem: "", data_considerada: "", cep: "", cidade: "", },
                     para: { nome: "", cep: "", cidade: "", bairro: "", rua: "", },
      previsao_de_entrega: { data: "", em_domicilio: "", },
              entregue_em: { data: "", },
    },
    objeto: {
      codigo: "",
      numero_rastreio: "",
      protocolo_retorno: "",
      habilita: false,
    },
    itensReclamacao: [],
    pi: {},
    principal: true,
    updateGrid: false,
  }),
  async mounted() {
    this.principal = true;
    // this.carregarEmpresa();
  },
  beforeMount() {
    this.filtroFechar = this.modal;
    this.tabItems(1);
    this.tabItRec(1);
    this.getFieldText(this.items[0].tab);
  },
  methods: {
    objetosQtde: function (send) {
    },
    tabItems(tab) {
      this.tab = tab.value;
      // this.updateGrid = !this.updateGrid;
    },
    tabItRec(tbRec) {
      this.tbRec = tbRec.value;
    },
    getFieldText(nameTab) {
      this.titleTab = nameTab;
      this.TotalObjetos( 0 );
      this.updateGrid = !this.updateGrid;
    },
    getSearch( value ) {
      if ( value == 1 ) {
        this.typereclama = "abertura";
      }
      else if ( value == 2 ) {
        this.typereclama = "reabertura";
      }
      this.updateGrid = !this.updateGrid;
    },
    limparFiltros() {
      this.filtros.codigo = "";
      this.filtros.numero_rastreio = "";
      this.filtros.pi = "";
      this.filtros.dt_prevista = "";
    },
    TotalObjetos(total) {
      this.grid.objetos.totalRegistros = total;
    },
    fecharFiltro() {
      this.modal.filtro = false;
    },
    carregarFiltro( paramFiltro ) {
      this.modal.filtro = false;
      this.filtros = paramFiltro;
    },
    fechar() {
      this.$router.push("/");
    },
  },
};
</script>


<style scoped>
.btnSalvar {
  color: #fff !important;
}

.active {
  background-color: #018656;
  color: #fff;
}
</style>
